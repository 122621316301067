import { useState, useEffect, useContext, useMemo, useCallback, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { requestOrganizations, requestOrganization, requestOrganizationCompanies } from 'api/SecurityApi';
import {
    faEdit,
    faSquarePlus,
    faDolly,
    faArrowsTurnRight,
    faCirclePause,
    faCirclePlay,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { roles } from 'components/Shared/AccessControl/privilegesMap';
import { CUST_TOOLS, OEC_STAGING_ORG } from '../../ManageCustomers/ManageCustomersConstants';
import withButton from '../../../Shared/Utils/withButton';
import ApiService from 'api/ApiService';
import { ApiEndpoints } from 'api/config/ApiEndpoints';
import { useSearchParams } from 'react-router-dom';
import { globalSearchType, testOrganizations } from '../../../Shared/Utils/Constants';
import { messages } from 'components/Shared/Utils/Constants';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';

const useOrganizationCompanies = (handleOrgEditClick = () => {}, setEditOrgId = () => {}) => {
    // higher order states
    const navigate = useNavigate();
    const { showToast } = useContext(ToastContext);
    const { hasRole } = useContext(AccessControlContext);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const location = useLocation();
    const params = useParams();
    const { notifications } = useContext(NotificationsContext);
    const { organizationId } = params; // user-provided organizationId
    // local states
    const userHasCustomerSupportRole = useMemo(() => hasRole(roles.customerSupport), [hasRole]);
    const [org, setOrg] = useState(null);
    const [orgs, setOrgs] = useState([]);
    const [orgCompanies, setOrgCompanies] = useState([]);
    const [displayCompanies, setDisplayCompanies] = useState([]);
    const [editCompanyId, setEditCompanyId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [sortedPropertyKey, setSortedPropertyKey] = useState(null);
    const [sortedPropertyDirection, setSortedPropertyDirection] = useState(null);
    const [showOrgModal, setShowOrgModal] = useState(false);
    const [searchParams] = useSearchParams();

    const searchParamText = searchParams.get('search');
    const searchParamKey = searchParams.get('searchKey');
    const globalSearchRef = useRef(null);
    const tableColumnConfigs = [
        { title: 'Id', columnProperty: 'companyId', sortable: true, show: true },
        { title: 'Location Name', columnProperty: 'companyName', sortable: true, show: true },
        { title: 'Address', columnProperty: 'address', sortable: true, show: true },
        { title: 'City', columnProperty: 'city', sortable: true, show: true },
        { title: 'State', columnProperty: 'state', sortable: true, show: true },
        { title: 'ZIP', columnProperty: 'zip', sortable: true, show: true },
        { title: 'Country', columnProperty: 'countryName', sortable: true, show: true },
        {
            title: 'Organization',
            columnProperty: 'organizationName',
            sortable: true,
            show: searchParamText != null,
        },
        { title: 'Phone', columnProperty: 'businessPhone', sortable: true, show: true },
        { title: 'Website', columnProperty: 'website', sortable: true, show: true },
        {
            title: 'Status',
            columnProperty: 'status',
            textProperty: 'statusText',
            filterable: true,
            sortable: true,
            show: true,
        },
    ];
    const [tableColumnDetails, setTableColumnDetails] = useState(tableColumnConfigs);
    const isOrgActive = org?.isActive;
    const [showMoveLocationModal, setShowMoveLocationModal] = useState(false);
    const [editedMoveLocation, setEditedMoveLocation] = useState(null);
    const [updatedLocationStatus, setUpdatedLocationStatus] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const hasMoveLocationAccess = useMemo(() => {
        return {
            hasAdminRole: hasRole(roles.admin),
            hasCustomerSuccesstRole: hasRole(roles.customerSuccess),
            hasSiteAdminRole: hasRole(roles.siteAdmin),
        };
    }, [hasRole]);

    useEffect(() => {
        setTableColumnDetails(config =>
            config.map(columnDetails => {
                let updatedColumnDetails = { ...columnDetails };
                if (searchParamText !== null && updatedColumnDetails.columnProperty === 'status') {
                    updatedColumnDetails.filterable = true;
                }
                if (updatedColumnDetails.filterable) {
                    if (!updatedColumnDetails.filterConfig) {
                        updatedColumnDetails.filterConfig = {};
                    }
                    updatedColumnDetails.filterConfig.filterOptions = Array.from(
                        new Set(
                            orgCompanies.map(
                                orgCompany =>
                                    orgCompany[updatedColumnDetails.textProperty] ??
                                    orgCompany[updatedColumnDetails.columnProperty]
                            )
                        )
                    ).map(x => ({
                        label: x,
                        value: x,
                    }));
                }
                return updatedColumnDetails;
            })
        );
    }, [orgCompanies, searchParamText]);

    const handleUpdateLocationStatusClick = useCallback(
        (e, companyId, companyName, isActive) => {
            e.stopPropagation();
            setUpdatedLocationStatus({ companyId, companyName, isActive, showConfirmationModal: true });
        },
        [setUpdatedLocationStatus]
    );

    const handleUpdateLocationStatus = useCallback(async () => {
        if (!updatedLocationStatus?.companyId) return;

        try {
            incrementLoading();
            await ApiService.patch(
                ApiEndpoints.location.updateStatus(updatedLocationStatus.companyId, !updatedLocationStatus.isActive)
            );

            notifications.pushSuccess(
                `${updatedLocationStatus.companyName} ${
                    updatedLocationStatus.isActive ? `deactivated` : `reactivated`
                } successfully!`
            );
            setUpdatedLocationStatus(null);

            onSaveOrEdit();
        } catch (error) {
            notifications.pushExceptionDanger(new Error('Request has been failed. Please try again.'));
        } finally {
            decrementLoading();
        }
    }, [updatedLocationStatus, incrementLoading, notifications, onSaveOrEdit, decrementLoading]);

    //Updating navbar breadcrumb
    useEffect(() => {
        if (!org || (location && location.state && location.state.org)) return;
        navigate(`${location.pathname}${location.search}`, { state: { org, allOrgs: orgs, orgComps: orgCompanies } });
    }, [navigate, location, org, orgs, orgCompanies]);

    useEffect(() => {
        const getOrgs = async () => {
            const hasLocationState = location && location.state;
            const allOrgsSet = hasLocationState && location.state.allOrgs;
            const selectedOrgSet = hasLocationState && location.state.org;

            if (allOrgsSet) {
                setOrgs(location.state.allOrgs);
            } else {
                try {
                    incrementLoading();
                    const orgs = await requestOrganizations();
                    setOrgs(orgs);
                } catch (error) {
                    showToast(error);
                } finally {
                    decrementLoading();
                }
            }

            if (selectedOrgSet) {
                setOrg(location.state.org);
            } else if (organizationId) {
                try {
                    incrementLoading();
                    const org = await requestOrganization(organizationId);
                    setOrg(org);
                } catch (error) {
                    showToast(error);
                } finally {
                    decrementLoading();
                }
            }
        };

        getOrgs();
    }, [organizationId, showToast, incrementLoading, decrementLoading, location, setOrg, setOrgs]);

    const cleanDisplayCompanies = orgComps =>
        orgComps && orgComps.length > 0
            ? orgComps.map(c => ({ ...c, countryName: c.country?.countryName || '' }))
            : orgComps;

    useEffect(() => {
        const getOrgCompanies = async () => {
            try {
                incrementLoading();
                await fetchCompanies(organizationId, searchParamKey, searchParamText);
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };

        getOrgCompanies();
    }, [
        organizationId,
        showToast,
        incrementLoading,
        decrementLoading,
        location,
        searchParamKey,
        searchParamText,
        fetchCompanies,
    ]);

    const fetchCompanies = useCallback(async (organizationId, companyId, searchParamText) => {
        let updatedOrgCompanies = [];
        if (searchParamText) {
            const searchRes = await ApiService.post(ApiEndpoints.organization.globalSearch, {
                searchText: searchParamText,
                searchType: globalSearchType.company,
            });
            updatedOrgCompanies = searchRes?.company?.results ?? [];
        } else if (companyId) {
            const company = await ApiService.get(ApiEndpoints.company.fetchById(companyId));
            updatedOrgCompanies = [company];
        } else {
            updatedOrgCompanies = await requestOrganizationCompanies(organizationId);
        }

        const orgCompanies = updatedOrgCompanies.map(o => ({
            ...o,
            status: !o.isDeactivated ? 'Active' : 'Deactivated',
        }));

        setOrgCompanies(orgCompanies);
        setDisplayCompanies(cleanDisplayCompanies(updatedOrgCompanies));
        if (globalSearchRef.current) {
            globalSearchRef.current.focus();
        }
        return updatedOrgCompanies;
    }, []);

    const handleRowClick = useMemo(() => {
        if (!orgCompanies || orgCompanies.length === 0) return () => {};
        return clickedObject => {
            const locationId = clickedObject.companyId;
            const company = orgCompanies.find(c => c.companyId === locationId);
            if (company) {
                navigate(`${CUST_TOOLS.compUserTool.Path(company.organizationId, locationId)}`, {
                    state: { allOrgs: orgs, org: org, company: company },
                });
            }
        };
    }, [navigate, orgCompanies, orgs, org]);

    const handleOrgUsersClick = useMemo(() => {
        if (!organizationId) return () => {};
        return () => {
            navigate(`${CUST_TOOLS.orgUserTool.Path(organizationId)}`, {
                state: { allOrgs: orgs, org: org, orgComps: orgCompanies, company: null },
            });
        };
    }, [navigate, orgs, org, orgCompanies, organizationId]);

    const handleEditClick = (e, companyId) => {
        setEditCompanyId(companyId);
        setShowModal(true);
        e.stopPropagation();
    };

    const handleCreateOrgClick = useCallback(
        (e, companyId) => {
            setEditCompanyId(companyId);
            handleOrgEditClick(e, null /* create new org */);
            setShowOrgModal(true);
            e.stopPropagation();
        },
        [setEditCompanyId, setShowOrgModal, handleOrgEditClick]
    );

    const handleCompModalToggle = useCallback(
        newCompId => {
            if (showModal) setEditCompanyId(newCompId);
            setShowModal(!showModal);
            if (!newCompId) setEditOrgId(null);
        },
        [showModal, setEditCompanyId, setShowModal, setEditOrgId]
    );

    const handleOrgModalToggle = (targetOrgId, stagedCompId) => {
        if (targetOrgId) setEditOrgId(targetOrgId);
        setShowOrgModal(!showOrgModal);
        if (targetOrgId && stagedCompId) handleCompModalToggle(stagedCompId);
    };

    const onSaveOrEdit = useCallback(async () => {
        try {
            incrementLoading();
            await fetchCompanies(organizationId, searchParamKey, searchParamText);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    }, [
        decrementLoading,
        fetchCompanies,
        incrementLoading,
        organizationId,
        searchParamKey,
        searchParamText,
        showToast,
    ]);

    const sortClickCallback = (retSortedProperty, retSortedPropertyDirection) => {
        setSortedPropertyKey(retSortedProperty);
        setSortedPropertyDirection(retSortedPropertyDirection);
    };

    // Search Companies
    useEffect(() => {
        let newSortedAndSearchedCompanies = orgCompanies.map(c => ({ ...c }));

        //If there is a column requested to be sorted by, sort by that property and the sort direction
        if (sortedPropertyKey !== null) {
            if (sortedPropertyDirection === 'A')
                newSortedAndSearchedCompanies.sort((a, b) => {
                    if (sortedPropertyKey === 'companyId') return a[sortedPropertyKey] < b[sortedPropertyKey] ? -1 : 1;
                    else return a[sortedPropertyKey]?.toLowerCase() < b[sortedPropertyKey]?.toLowerCase() ? -1 : 1;
                });
            else
                newSortedAndSearchedCompanies.sort((a, b) => {
                    if (sortedPropertyKey === 'companyId') return a[sortedPropertyKey] < b[sortedPropertyKey] ? 1 : -1;
                    else return a[sortedPropertyKey]?.toLowerCase() < b[sortedPropertyKey]?.toLowerCase() ? 1 : -1;
                });
        }

        // todo: use fuzzy search
        if (searchTerm) {
            newSortedAndSearchedCompanies = orgCompanies.filter(
                c =>
                    c.companyId.toString().indexOf(searchTerm) > -1 ||
                    (c.companyName && c.companyName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (c.address && c.address.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (c.zip && c.zip.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (c.city && c.city.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (c.state && c.state.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (c.countryName && c.countryName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (c.businesPhone && c.businesPhone.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (c.website && c.website.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
            );
        }

        // Add component to object to table can render action component
        newSortedAndSearchedCompanies.forEach(c => {
            //If user is customer support hide edit org button
            if (userHasCustomerSupportRole) {
                c.actions = null;
            } else if (parseInt(organizationId) === OEC_STAGING_ORG.organizationId) {
                c.actions = (
                    <div className="table-actions">
                        <button type="button" className="btn p-0 m-0 border-0">
                            <div className="action" onClick={e => handleEditClick(e, c.companyId)}>
                                <FontAwesomeIcon
                                    id={`staged-company-${c.companyId}-transfer`}
                                    className="text-primary clickable customer-action-button"
                                    icon={faDolly}
                                />
                                <span>Add to Existing Org</span>
                            </div>
                        </button>
                        <button type="button" className="btn p-0 m-0 border-0">
                            <div className="action" onClick={e => handleCreateOrgClick(e, c.companyId)}>
                                <FontAwesomeIcon
                                    id={`staged-company-${c.companyId}-transfer`}
                                    className="text-primary clickable m-1 customer-action-button"
                                    icon={faSquarePlus}
                                />
                                <span>Create Organization</span>
                            </div>
                        </button>
                    </div>
                );
            } else {
                const EditButton = withButton(() => (
                    <FontAwesomeIcon
                        id={`company-${c.companyId}-edit`}
                        className="text-primary clickable m-1 customer-action-button"
                        icon={faEdit}
                    />
                ));

                const MoveLocationButton = withButton(() => (
                    <FontAwesomeIcon
                        id={`company-${c.companyId}-move`}
                        className={`text-primary clickable m-1 customer-action-button`}
                        icon={faArrowsTurnRight}
                    />
                ));

                const UpdateStatusAction = withButton(() => (
                    <FontAwesomeIcon
                        className="text-primary clickable customer-action-button"
                        title={!c.isDeactivated ? 'Deactivate' : 'Reactivate'}
                        id={`${
                            !c.isDeactivated ? `location-${c.companyId}-deactivate` : `location-${c.companyId}-activate`
                        }`}
                        icon={!c.isDeactivated ? faCirclePause : faCirclePlay}
                    />
                ));

                const isLocationActive = !c.isDeactivated;
                const isActive = isOrgActive && isLocationActive;
                let disabledMessage = '';

                if (!isActive) {
                    if (!isOrgActive) {
                        disabledMessage = messages.organizationDisabledActionNotAllowed;
                    } else if (!isLocationActive) {
                        disabledMessage = messages.companyDisabledActionNotAllowed;
                    }
                }

                c.actions = (
                    <div className="table-actions">
                        <EditButton
                            isActive={isActive}
                            label="Edit Information"
                            onClick={e => handleEditClick(e, c.companyId)}
                            title={disabledMessage}
                        />
                        <UpdateStatusAction
                            isActive={isOrgActive && !testOrganizations.includes(parseInt(organizationId))}
                            label={isLocationActive ? 'Deactivate' : 'Activate'}
                            title={
                                testOrganizations.includes(parseInt(organizationId))
                                    ? messages.deactivationDisabledForTestOrgLocations
                                    : !isOrgActive
                                    ? messages.organizationDisabledActionNotAllowed
                                    : ''
                            }
                            onClick={e =>
                                handleUpdateLocationStatusClick(e, c.companyId, c.companyName, !c.isDeactivated)
                            }
                        />
                        {hasMoveLocationAccess && (
                            <MoveLocationButton
                                label="Move Location"
                                isActive={isActive}
                                onClick={e => handleShowMoveLocationModal(e, c)}
                                title={disabledMessage}
                            />
                        )}
                    </div>
                );
            }
            c.status = !c.isDeactivated ? (
                <span className="text-success">Active</span>
            ) : (
                <span className="text-danger">Deactivated</span>
            );
            c.statusText = !c.isDeactivated ? 'Active' : 'Deactivated';
        });

        if (selectedFilters.length) {
            for (let filter of selectedFilters) {
                newSortedAndSearchedCompanies =
                    filter.optionsSelected &&
                    newSortedAndSearchedCompanies.filter(u =>
                        filter.optionsSelected.some(x => x.toLowerCase() == u[filter.columnName].toLowerCase())
                    );
            }
        }

        setDisplayCompanies(newSortedAndSearchedCompanies);
    }, [
        organizationId,
        orgCompanies,
        searchTerm,
        sortedPropertyKey,
        sortedPropertyDirection,
        userHasCustomerSupportRole,
        handleCreateOrgClick,
        setShowOrgModal,
        hasMoveLocationAccess,
        handleShowMoveLocationModal,
        handleUpdateLocationStatusClick,
        isOrgActive,
        selectedFilters,
    ]);

    const handleShowMoveLocationModal = useCallback((e, company) => {
        e && e.stopPropagation();
        setShowMoveLocationModal(prevShowMoveLocationModal => !prevShowMoveLocationModal);
        setEditedMoveLocation(company);
    }, []);

    const filterChangeCallback = useCallback(filters => {
        setSelectedFilters(prevFilters => {
            let updatedFilters = prevFilters.filter(filter => filter.columnName != filters.columnName);
            if (filters.optionsSelected.length) {
                updatedFilters = [...updatedFilters, filters];
            }
            return updatedFilters;
        });
    }, []);

    return {
        showModal,
        organizationId,
        editCompanyId,
        org,
        searchTerm,
        displayCompanies,
        tableColumnDetails,
        userHasCustomerSupportRole,
        onSaveOrEdit,
        handleCompModalToggle,
        setSearchTerm,
        handleOrgUsersClick,
        setShowModal,
        handleRowClick,
        handleEditClick,
        sortClickCallback,
        showOrgModal,
        handleOrgModalToggle,
        isOrgActive,
        showMoveLocationModal,
        editedMoveLocation,
        handleShowMoveLocationModal,
        handleUpdateLocationStatus,
        updatedLocationStatus,
        setUpdatedLocationStatus,
        searchParamText,
        filterChangeCallback,
        selectedFilters,
        globalSearchRef,
    };
};

export default useOrganizationCompanies;
